export const commonKey = (title, scope, widthOrOptions, isSlot, slotName) => {
  let keys = {
    title,
    dataIndex: scope,
  }

  let width = 'auto';

  if (widthOrOptions && typeof widthOrOptions === 'object') {
    width = widthOrOptions.width
    isSlot = widthOrOptions.isSlot
    slotName = widthOrOptions.slotName

    keys = Object.assign(keys, widthOrOptions);
  } else {
    width = widthOrOptions
  }

  keys.width = width ? width : 'auto'

  isSlot && (keys.scopedSlots = {
    customRender: slotName || scope
  })

  return keys;
}

export const colId = {
  title: 'ID',
  customRender(text, record, index) {
    return index + 1;
  },
  width: '50px'
};

export const colIdFunc = function (func, title = 'ID', width = '50px') {
  return {
    title,
    customRender(text, record, index) {
      return typeof func === 'function' ? func(text, index) : index + 1;
    },
    width
  }
}

export const portrait = (title = '头像', scope = 'profile_photo', width = '100px') => commonKey(title, scope, width, true);

export const operation = (width = '200px') => commonKey(process.env.VUE_APP_TYPE === 'supplier_en' ? 'Options' : '操作', 'operation', width, true);

export const timeArea = (width = '200px') => commonKey('有效时间', 'timeArea', width, true);
export const couponArea = (width = '200px') => commonKey('优惠内容', 'couponArea', width, true);
export const thresholdArea = (width = '200px') => commonKey('使用门槛', 'thresholdArea', width, true);